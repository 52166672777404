body {
  margin: 0px;
  font-family: -apple-system, Poppins, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfd;
}

h1 {
  height: 38px;

  font-style: "normal";
  font-weight: 500;
  font-size: 24px;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
}

h4 {
  font-weight: 500;
  margin: 12px 0;
}

.toastifyStyle {
  --toastify-color-progress-light: #727fdb;
  --toastify-color-progress-warning: #c2a08d;
  --toastify-font-family: -apple-system, Poppins, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.whiteWrapper {
  height: 100vh;
  background-color: white;
  box-sizing: border-box;
}

.loginStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 6%;
}

.inputBox {
  width: 318px;
  height: 40px;

  /* Base/White */
  background: #ffffff;
  /* Gray/300 */
  border: 1px solid #d0d5dd;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  /* Text */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 24px2 */
  padding-left: 12px;
}

.inputBoxInfoBellow {
  font-size: 14px;
  margin-top: -12px;
  color: #667085;
  margin-bottom: 20px;
  width: 330px;
}

.oldInput {
  background-color: #f2f4f7;
  margin-top: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 14px;
}

.marginLeft12 {
  margin-left: 12px;
}

.searchIcon {
  width: 290px;
  height: 40px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  background: white
    url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z' stroke='%23667085' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat 10px center;
  padding-left: 36px;
}

.boxBoxSelect {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  /* Gray/300 */

  /* Shadow/xs */

  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;
}

.boxSelect {
  border: 1px solid #d0d5dd;
  vertical-align: middle;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  /* border-right: 1px solid #d0d5dd; */
  border-right: 1px solid #d0d5dd;
  background: white;
  white-space: nowrap;

  font-weight: 500;
  font-size: 12px;
}

.greyBackgroundSelect {
  background: #f9fafb;
}

.boxBoxSelect > .boxSelect:not(:last-child) {
  border-right: none;
}

.boxBoxSelect > .boxSelect:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.boxBoxSelect > .boxSelect:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.wrapperNoMargin > p {
  margin: 0px;
}

.roundedButton {
  width: 318px;
  height: 44px;

  /* Primary/600 */
  background: #151e37;
  /* Primary/600 */
  border: 1px solid #151e37;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 40px;

  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
  color: white;
  cursor: pointer;
  margin-top: 24px;
}

.link14px {
  margin: 0px;
  cursor: pointer;
}

.emailSent {
  margin: -10px 0px 0px 0px;
  font-size: 14px;
  color: #667085;
}

.iconStyle {
  max-width: 22px;
  max-height: 22px;
}

.inputTopRight {
  position: absolute;
  top: 24px;
  right: 24px;
}

.smallPie {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 46px;
}

.smallBox {
  height: 36px;
}

.filterPurpleArrow {
  filter: invert(49%) sepia(96%) saturate(279%) hue-rotate(195deg)
    brightness(87%) contrast(99%);
}

.borderRightRadius {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.borderLeftRadius {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.greenFinished {
  color: #32d583;
}

/* ----- MENU ----- */

.navLink {
  text-decoration: none;
  color: #344054;
}

.filterPurple {
  text-decoration: none;
}

.filterPurple > div > img {
  filter: invert(49%) sepia(96%) saturate(279%) hue-rotate(195deg)
    brightness(87%) contrast(99%);
}

.filterPurple > div > p {
  color: #727fdb;
}

.filterPurple > div {
  background-color: #eff4ff;
}

/* ----- TABLES ----- */
/* ----- TABLES ----- */
/* ----- TABLES ----- */

.styled-table table {
  /* background: green; */
  width: 100%;
  border-spacing: 0px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

.styled-table thead {
  border: 1px solid blue;
}

/* case head */
.styled-table th {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 16px;
  text-align: left;
  white-space: nowrap;
  color: #667085;
}

.styled-table tbody {
}

.styled-table tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 8px;
}

.styled-table tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 8px;
}

/* case body */
.styled-table td {
  box-sizing: border-box;
  background: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid #eaecf0;
  padding: 16px;
}

/* line */
.styled-table tr {
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}

/* ----- TAB SELECTION ----- */
/* ----- TAB SELECTION ----- */
/* ----- TAB SELECTION ----- */

.tabSelect {
  display: flex;
  /* Gray/50 */
  background: #f9fafb;
  /* Gray/100 */
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-top: 22px;
}

.smallButton {
  background-color: white;
  margin: 2px;
  border-radius: 4px;
  border: 1px solid rgb(208, 213, 221);
}

.tabButton {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.tabOn {
  background-color: white;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
}
